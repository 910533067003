import React from "react";
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import ContactUs from './pages/ContactUs';
import LearningCenter from './pages/LearningCenter';
import Legal from './pages/Legal';
import { Helmet } from 'react-helmet';
import { AppContainer } from "./styles/SharedStyle";

function App() {
  return (
    <AppContainer>
       <Helmet>
        <link rel="preload" href="fonts/EBGaramond-Regular.ttf" as="font" crossorigin="anonymous"/>
        <link rel="preload" href="fonts/OpenSauceOne-Light.ttf" as="font" crossorigin="anonymous"/>
        <meta name="description" content="Welcome to Kalyani!"/>
        <link rel="icon" href="assets/brandmark.png"/>
      </Helmet>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<ContactUs/>} />
          <Route path='/About' element={<About/>} />
          <Route path='/LearningCenter' element={<LearningCenter/>} />
          <Route path='/ContactUs' element={<ContactUs/>} />
          <Route path='/Legal' element={<Legal/>} />
        </Routes>
      </Router>
    </AppContainer>
  )
};

export default App;
