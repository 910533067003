import styled from 'styled-components';

export const ContactUsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;

    @media only screen and (max-width: 420px) {
        height: 65vh;
    }
`;
