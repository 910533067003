import styled from 'styled-components';

export const LearningCenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    margin-top: 180px;
    width: 100%;
    max-width: 700px; /* Set the desired maximum width */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    
    /* Media query for screens with width smaller than 800px (tablets and smartphones) */
    @media only screen and (max-width: 800px) {
        max-width: 90%; /* Adjust the maximum width for smaller screens if needed */
    }
    @media only screen and (max-width: 400px) {
        margin-top: 90px;
    }
    @media only screen and (min-width: 401px) and (max-width: 500px) {
        margin-top: 100px;
    }
    @media only screen and (min-width: 501px) and (max-width: 1100px) {
        margin-top: 140px;
    }
`;

export const LearningCenterHeader = styled.div`
    margin-bottom: 8px;
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    text-align: left;
    /* Product/Dark Charcoal */
    color: #333333;
`;

export const LearningCenterSection = styled.div`
  width: 100%;
`;

export const HorizontalDivider = styled.hr`
  border: 1px solid #E5ECEC;
  transform: rotate(180deg);
  margin-top: 24px;
  margin-bottom: 24px;
`;