import styled from 'styled-components';

export const AboutContainer = styled.div`
    /* used in screen views with width smaller than 800px (tablets and smartphones) */
    @media only screen and (max-width: 800px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-left: 24px;
        padding-right: 24px;
    }
    @media only screen and (max-width: 400px) {
        margin-top: 80px;
    }
    @media only screen and (min-width: 401px) and (max-width: 500px) {
        margin-top: 120px;
    }
    @media only screen and (min-width: 501px) and (max-width: 1100px) {
        margin-top: 140px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    margin-bottom: 50px;
`;

export const AboutHeader = styled.div`
    /* used in screen views with width smaller than 800px (tablets and smartphones) */
    @media only screen and (max-width: 800px) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    margin-top: 20px;
    width: 640px;
    left: 400px;
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    /* Product/Dark Charcoal */
    color: #333333;
`;

export const AboutSection = styled.div`
    margin-top: 25px;
    margin-bottom: 20px;
    width: 640px;
    left: 400px;
    font-family: 'Open Sauce One';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    /* Product/Dark Charcoal */
    color: #333333;
`;
