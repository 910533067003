import styled from 'styled-components';

export const SidebarLink = styled.div`
    padding-bottom: 30px;
    font-family: 'Open Sauce One';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    cursor: pointer;
`;

export const LegalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0; /* Adjust the padding as needed */
  margin-top: 180px;
  margin-bottom: 50px;

  /* Media query for screens with width smaller than 800px (tablets and smartphones) */
  @media only screen and (max-width: 800px) {
    padding: 0;
    flex-direction: column;
  }
  @media only screen and (max-width: 400px) {
    margin-top: 70px;
  }
  @media only screen and (min-width: 401px) and (max-width: 500px) {
    margin-top: 100px;
  }
  @media only screen and (min-width: 501px) and (max-width: 1100px) {
    margin-top: 140px;
  }
`;

export const SideBar = styled.div`
  width: 15%;
  max-width: 300px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 60px; /* Adjust the padding as needed */
  padding-left: 20px; /* Adjust the padding as needed */

  /* Media query for screens with width smaller than 800px (tablets and smartphones) */
  @media only screen and (max-width: 800px) {
    flex-wrap: wrap;
    width: 80%;
    max-width: none;
    min-width: 0;
    left: auto;
    margin-bottom: 20px;
    // margin-left: 5%;
    margin-right: auto;
  }
  @media only screen and (min-width: 501px) and (max-width: 800px) {
    margin-left: 5%;
    padding-left: 0;
  }
`;

export const MainBody = styled.div`
  max-width: 700px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // padding-right: 20px; /* Adjust the padding as needed */
  text-align: justify;

  /* Media query for screens with width smaller than 800px (tablets and smartphones) */
  @media only screen and (max-width: 800px) {
    // max-width: 800px;
    flex-wrap: wrap;
    margin-left: 5%;
    margin-right: 5%;
    top: 400px;
  }
  @media only screen and (min-width: 801px) {
    padding-right: 40px;
  }
`;

export const LegalMainTitle = styled.div`
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  padding-bottom: 30px;
  text-align: left;
`;

export const LegalMainSection = styled.div`
  font-family: 'Open Sauce One';
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 30px;
  letter-spacing: -2%;
  text-align: justify;
`;

/*export const LegalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    left: 30vw;
    right: 30vw;
    margin-top: 200px;
    text-align: justify;
    justify-content: center;

    @media only screen and (max-width: 800px) {
        margin-left: 0;
        margin-top: 0;
    }
`;

export const SideBar = styled.div`
  width: 15%;
  max-width: 300px;
  min-width: 200px;
  position: absolute;
  z-index: 1;
  top: 300px;
  left: 25vw;
  overflow-x: hidden;
  display: flex;
  justify-content: right;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: 800px) {
    width: 100%;
    max-width: none;
    left: auto;
    margin-bottom: 20px;
    margin-left: 5%;
    margin-right: auto;
    top: 240px;
  }
`;


export const SidebarLink = styled.div`
    padding-bottom: 30px;
    font-family: 'Open Sauce One';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    cursor: pointer;
`;


export const MainBody = styled.div`
  position: absolute;
  z-index: 1;
  max-width: 700px;
  min-width: 500px;
  top: 300px;
  margin-right: 30vw;
  left: 40vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: 800px) {
    margin-left: 5%;
    margin-right: 25%;
    top: 400px;
  }
`;

export const LegalMainTitle = styled.div`
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    padding-bottom: 30px;
`;

export const LegalMainSection = styled.div`
    font-family: 'Open Sauce One';
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 30px;
    letter-spacing: -2%;
`;*/